<template>
    <div class="select">
        <el-select v-model="params.province_id">
            <el-option v-for="item in provinceList" :key="item.id" :label="item.local_name" :value="item.id">

            </el-option>
        </el-select>
    </div>
</template>

<script>
import * as API_Address from '@/api/expressMould'
export default {
    name: 'EnAddressSelector',
    props: {
        params: {
            type: Object,
            default: () => {
                return {
                    province_id: '',
                    city_id: '',
                    county_id: '',
                    town_id: '',
                    province: '',
                    city: '',
                    county: '',
                    town: ''
                }
            }
        },
        provinceList: {
            type: Array,
            default: []
        }
    },
    data() {
        return {
            cityList: [],
            countyList: [],
            townList: []
        }
    },
    mounted() {
        // this.getAddressList(0)
    },
    methods: {
        getAddressList(id) {
            API_Address.getBaseAddress(id).then(res => {

            })
        }
    }
}
</script>